<template>
  <div v-if="!loading">
    <v-toolbar color="#1a1a27" class="text-white rounded-0" height="80px">
      <img v-if="asideEnabled" :src="siteLogo()" alt="Logo" width="75" />
      <v-spacer v-if="asideEnabled"></v-spacer>
      <v-toolbar-title class="ml-3">
        <h2 class="mb-0">Onay Bekleyen İşlemlerim</h2>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <KTTopbar></KTTopbar>
    </v-toolbar>
    <v-row v-if="offers?.length > 0" class="mx-4 mt-5 mt-i-5">
      <v-col v-for="offer in offers" :key="offer?.id" md="6" xl="4" cols="12">
        <v-card
          elevation="20"
          dark
          color="#1a1a27"
          class="pa-4"
          height="auto"
          rounded="xl"
        >
          <div
            :class="$vuetify.breakpoint.xs ? 'flex-column' : null"
            class="d-flex flex-row justify-content-between"
          >
            <div class="align-self-center">
              <img :src="siteLogo()" alt="Logo" width="100" />
            </div>
            <v-divider
              class="ml-5 mr-5"
              :vertical="!$vuetify.breakpoint.xs"
            ></v-divider>
            <div>
              <v-card-text
                class="d-flex flex-column justify-content-end item-aligns-center text-sm-body-1 text-md-h6"
              >
                <v-row>
                  <v-col class="mr-2 mb-1" tag="strong" cols="5">
                    Ürün İsmi:
                  </v-col>
                  <v-col cols="6">
                    <span style="color: #ef5350">
                      {{
                        offer.production.display_name ??
                        offer.third_base_class_choice_name
                      }}
                    </span>
                  </v-col>
                  <v-col class="mr-2 mb-1" tag="strong" cols="5">
                    İşlem Tarihi:
                  </v-col>
                  <v-col cols="6">
                    <span style="color: #ef5350">
                      {{
                        new Date(offer?.created_at).toLocaleDateString("tr-TR")
                      }}
                    </span>
                  </v-col>
                  <v-col class="mr-2 mb-1" tag="strong" cols="5">
                    Ödeme Şekli:
                  </v-col>
                  <v-col cols="6">
                    <span style="color: #ef5350">
                      {{ offer?.payment_method_name }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-content-center">
                <router-link
                  :to="{
                    name: 'user-offers-view',
                    params: { id: offer?.payment_plan?.id },
                  }"
                >
                  <v-btn elevation="10" color="primary">
                    İşlemi Görüntüle
                  </v-btn>
                </router-link>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-sheet
        elevation="0"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto mt-10"
      >
        <v-icon class="mb-5" color="info" size="112"> mdi-alert-circle </v-icon>

        <h2 class="text-h5 mb-6">
          Onay Bekleyen Bir İşleminiz Bulunmamaktadır.
        </h2>
      </v-sheet>
    </v-row>
  </div>
  <div
    v-else
    style="height: 250px"
    class="d-flex flex-column justify-content-end align-items-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      width="7"
    ></v-progress-circular>
    <h3 class="mt-5">Lütfen bekleyiniz...</h3>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import objectPath from "object-path";
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";

import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  name: "OfferList",
  components: { KTTopbar },
  data() {
    return {
      loading: true,
      offers: [],
    };
  },
  async created() {
    try {
      this.offers = await this.getOffers();
      this.loading = false;
    } catch (error) {
      this.$toast.error(error, {
        duration: 2000,
        position: "bottom-right",
      });
    }
  },
  methods: {
    getOffers() {
      return new Promise((resolve, reject) => {
        AxiosInstance.get(`/offers`, {
          params: {
            offer_status: "sent",
          },
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data.errors);
          });
      });
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    asideEnabled() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
});
</script>

<style></style>
